body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: #000;
  /*cursor: url('../assets/svg/cursor-main.svg') -20 -15, default;*/
}

.lds-roller-wrapper {
  padding-top: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

//@font-face {
//  font-family: 'GoogleSans';
//  src: url('../assets/fonts/ProductSans-Light.ttf') format('truetype');
//  font-weight: 300;
//}
//
//@font-face {
//  font-family: 'GoogleSans';
//  src: url('../assets/fonts/ProductSans-Regular.ttf') format('truetype');
//  font-weight: 400;
//}
//
//@font-face {
//  font-family: 'GoogleSans';
//  src: url('../assets/fonts/ProductSans-Medium.ttf') format('truetype');
//  font-weight: 500;
//}
//
//@font-face {
//  font-family: 'GoogleSans';
//  src: url('../assets/fonts/ProductSans-Bold.ttf') format('truetype');
//  font-weight: 700;
//}
//
//@font-face {
//  font-family: 'DeusEx';
//  src: url('../assets/fonts/deusex.otf') format('opentype');
//  font-weight: 700;
//}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Thin.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Extrabold.ttf') format('truetype');
  font-weight: 800;
}

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;
  /* firefox scroll */
  scrollbar-width: thin;
  scrollbar-color: #98a2b3 #000428;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.15);
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #98a2b3;
}

.section-title {
  text-align: center;
  font-size: 30px;
  @media (max-width: 420px) {
    font-size: 26px;
  }
}

.title-icon {
  color: #aaa;
  margin-top: 20px;
  padding-top: 20px;
  font-size: 32px;
  display: block;
  text-align: center;
}

section {
  width: 100%;
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  transition: all 0.4s;
}

/*button, input[type='checkbox'],
a,
a * {
  cursor: url('../assets/svg/cursor-pointer.svg') -20 -15, pointer;
}*/


/*input[type='text'],
input[type='email'],
input[type='tel'],
input,
label {
  cursor: url('../assets/svg/dvutavr.svg'), url('../assets/svg/cursor-pointer.svg'), pointer;
}*/
