@use '../../styles/variables.scss';

.appWrapper {
  transition: all .6s;
  overflow: hidden;
  color: #fff;
  background: #000;
  background: linear-gradient(109.6deg,rgba(0,0,0,.93) 11.2%,#3f3d3d 78.9%);
}

.appWrapperLight {
  transition: all .6s;
  background: #F8F0E4;
}

.mainContainer {
  /*padding: calc(variables.$header-height + 20px) 10px 50px;*/
  margin: 0 auto;
  max-width: variables.$container-width;
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;
}
