@use '../../styles/variables';

.aboutSec {
  min-height: 100vh;
  min-height: 100dvh;

  .langButton {
    background: url('../../assets/svg/uk_flag.svg') center / cover no-repeat;
    box-shadow: 0 0 3px rgb(0 0 0 / 70%);
    border-radius: 50%;
    min-width: 30px;
    width: 30px;
    height: 30px;
    font-size: 0;
    transition: all 0.4s, background 0.8s ease-in-out;
  }

  .langRu {
    background: url('../../assets/svg/ru_flag.svg') center / cover no-repeat;
  }
}

.aboutWrapper {


  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .aboutItem {
    width: 320px;
    margin: auto;
    position: relative;
    top: calc(50vh - 220px);
    padding: 5px 2px 5px 8px;
    height: 300px;
    @media (min-width: 700px) {
      transform: scale(1.4);
    }

    @media (min-width: 2000px) {
      transform: scale(2);
    }
    //backdrop-filter: blur(15px);
    //background: rgba(255,255,255,0.08);
    //border-radius: 30px;
  }

  .aboutImg {
    height: 640px;

    @media (max-width: 767px) {
      height: 340px;
      margin: 30px 10px 5px 10px;
    }

    @media (max-width: 500px) {
      padding: 5px;
    }

    background: url('../../assets/svg/about.svg') center / contain no-repeat;
  }

  .buttonsWrapper {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }

  .buttonFront {
    height: 44px;
    border-radius: 100px;
    font-weight: bold;
    width: 144px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.4s;

    &:hover {
      filter: brightness(1.1);
    }
  }

  .buttonType1 {
    margin-right: 15px;
    background-color: #0277bd;
  }

  .buttonType2 {
    background-color: #fff;
    color: #0277bd;
  }

  .textBig {
    font-size: 30px;
    margin-bottom: 4px;
  }

  .frontText {
    font-size: 13px;
    color: #ddd;
    line-height: 1.6em;
    // max-width: 380px;
  }

  .textReg {
    font-size: 22px;
    font-weight: 400;
    margin: 12px 0;
  }

  .textAnimated {
    font-size: 44px;
    margin: 12px 0;
    font-weight: 500;
  }

  .textJobTitle{
    font-size: 28px;
    margin: 12px 0;
    font-weight: 500;
    color: #12b886;
  }
}

.contactsContainer {
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  position: absolute;
  left: calc(50vw - 140px);
  bottom: 30px;


  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 25%);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border-radius: 100px;
  flex-direction: row;
  padding: 6px 15px;
  overflow: hidden;
  touch-action: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.contactsItem {
  background: transparent;
  color: #fff;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 38px;
  height: 38px;
  border: 0;
  font-size: 22px;
  border-radius: 50%;

  i {
    z-index: 1;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.aboutWrapperLight {
  color: #000428;

  .frontText {
    color: #000428;
  }


  .buttonType2 {
    box-shadow: inset 0 0 2px rgb(0 0 0 / 70%);
  }
}

.MuiSwitch-thumb {
  background-color: #000 !important;
}